import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import HeaderButton from 'components/Headers/HeaderButton';

import ProjectBody from '../ProjectBody';


const Idea = (): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      graph: contentfulAsset(title: {eq: "MJN-rozwojowosc-grafu"}) {
        fluid(maxWidth: 500) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  `);

  return (
    <ProjectBody>
      <div className="project-content">
        <h1>Nauka dotyczy nas wszystkich</h1>
        <p>
          Z osiągnięć nauki i technologii korzystamy codziennie, często w bardzo prozaicznych okolicznościach.
          Nie musimy zastanawiać się nad fizyką, przemieszczając się za pomocą współczesnych środków transportu. 
          Nie musimy myśleć o chemii, kiedy nabywamy leki, środki czystości czy nawet pożywienie. Granie na 
          komputerze czy korzystanie z internetu nie wymagają znajomości informatyki. Wszystkie te działania są
          dla nas całkiem naturalne, dopóki nie uświadomimy sobie jak niedawno zostały upowszechnione i z jak 
          rewolucyjną przemianą warunków naszego życia się wiążą. 
        </p>
        <h1>Jak nadążyć za nauką?</h1>
        <p>
          To, że warto zastanawiać się nad źródłem udogodnień naszej codzienności, nie podlega chyba dyskusji. 
          Problem polega jednak na tym, że nauka rozwija się tak szybko i w ramach tak licznych dziedzin, że 
          żaden pojedynczy człowiek - nawet człowiek nauki - nie jest w stanie za nią nadążyć. Na szczęście próba
          bycia na bieżąco z każdą gałęzią wiedzy nie jest jedyną sensowną strategią nawigowania po współczesnym, 
          tak mocno zdefiniowanym przez naukę świecie. 
        </p>
        <h1>Grunt to posiadać narzędzia!</h1>
        <p>
          W naszym projekcie chcemy Wam przybliżyć nie tyle efekty nauki - najnowsze odkrycia i teorie, co zestaw 
          narzędzi, który umożliwia ich osiąganie - metodę naukową. Wierzymy bowiem, że jej przyswojenie pozwala 
          zobaczyć poszczególne odkrycia i teorie w odpowiedniej perspektywie - zrozumieć skąd się wzięły, jak je 
          interpretować i co z nich wynika. Osiągnięcie tej perspektywy jest zarazem znaczeniem naszego 
          przewodniego hasła - Myśl jak naukowiec.
        </p>
        <h1>Nie tylko o nauce można myśleć jak naukowiec</h1>
        <p>
          Korzyści z naukowego sposobu myślenia nie ograniczają się przy tym do samego świata nauki. Można myśleć 
          jak naukowiec o sprawach, które dotyczą bardziej przyziemnego wymiaru naszego indywidualnego i 
          społecznego życia. Nauka to przecież nie tylko przełomowe odkrycia, ale także uważność, metodyczność 
          i krytyczność na co dzień. Rozwijanie w sobie tych cech jest zaś doskonałym remedium na wszechobecne 
          w naszych czasach dezinformację, demagogię i manipulacje.
        </p>
        <h1>Grafy argumentacyjne</h1>
        <p>
          Głównym medium, z jakiego korzystamy w naszym projekcie są grafy argumentacyjne. Ta forma zapisu, dzięki 
          otwartości na wiele głosów oraz ułatwianiu krytycznego namysłu, daje nam możliwość zwięzłego 
          przedstawienia najistotniejszych momentów myślenia naukowego. Pozwala ona zarazem na twórcze 
          poszukiwania i odnajdywanie nowych odpowiedzi na stare pytania.
        </p>
        <p>
          W nauce - i podobnie na naszych grafach - nie ma twierdzeń ostatecznych. Każda teza wymaga uzasadnień i 
          jest zarazem otwarta na podważenia. Każda odpowiedź rodzi kolejne pytania, a każde pytanie otwiera nowe
          pola do dyskusji. Wreszcie, tak jak żadna teoria naukowa nie jest nigdy dziełem jednego, niezwiązanego 
          ze społecznością badaczy geniusza, tak i grafy wykraczają poza perspektywę jednostki, będąc zawsze 
          efektem pracy wielu osób. Wielu osób, w tym - mamy nadzieję - Was! Zapraszamy więc do twórczej pracy nad
          tworzeniem wspólnej przestrzeni dyskusji. Na każdym etapie rozwoju grafu każda osoba może włączyć się do 
          rozmowy - dodać swój argument i skonfrontować się z odmiennymi punktami widzenia. 
          <a href="https://youtu.be/rV2f8Ii1JpQ">Aby dowiedzieć się więcej odtwórz explainer</a>.
        </p>

        <Img fluid={data.graph.fluid} />
        <h1>Tematy projektu</h1>
        <p>
          Na koniec chcielibyśmy jeszcze opowiedzieć Wam o głównych zagadnieniach, które będziemy podejmować w 
          ramach naszego projektu. Co jednak niezwykle istotne, chcemy, abyście Wy również mieli możliwość 
          wpłynięcia na jego program. Jeśli chcecie, żebyśmy odnieśli się do jakiegoś konkretnego problemu, macie
          pomysł na dyskusję, którą możemy przeprowadzić na grafie argumentacji, albo chcecie zaproponować bardziej
          ogólny temat, o którym nie wspomnieliśmy w poniższym spisie - kliknijcie w przycisk pod spisem dyskusji. 
        </p>
        <h1>PROGRAM</h1>
        <ul>
          <li>Czym jest nauka?</li>
          <li>Jak rozwija się nauka? </li>
          <li>Pseudonauki wokół nas - na ile poważnym są problemem? </li>
          <li>Jaki jest krajobraz nauki? </li>
          <li>Społeczna odpowiedzialność nauki </li>
          <li>Jakie są limity poznania naukowego? </li>
          <li>Nauka a światopogląd, religie, ideologie </li>
          <li>Jak nauka wpływa na kulturę?</li>
          <li>Czy istnieje konflikt pomiędzy myśleniem naukowym a zdroworozsądkowym? </li>
          <li>Czy nauka i technologia mogą rozwijać się niezależnie od siebie? </li>
          <li>Czy nauka jest instytucją społeczną? </li>
          <li>Jaka jest istota i rola nauk formalnych? </li>
        </ul>
        <HeaderButton mode="sticky" discussion />
      </div>
    </ProjectBody>
  );
};

export default Idea;